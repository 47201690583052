const properties = [
  "display",
  "show",
  "visibility",
  "visible",
  "width",
  "minWidth",
  "maxWidth",
  "height",
  "minHeight",
  "maxHeight",
  "position",
  "top",
  "right",
  "bottom",
  "left",
  "zIndex",
  "overflow",
  "overflowX",
  "overflowY",
  "padding",
  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "paddingLeft",
  "pointerEvents",
  "margin",
  "marginTop",
  "marginRight",
  "marginBottom",
  "marginLeft",
  "background",
  "backgroundColor",
  "backgroundImage",
  "backgroundPosition",
  "backgroundRepeat",
  "backgroundSize",
  "boxShadow",
  "flexDirection",
  "alignItems",
  "alignContent",
  "justifyContent",
  "flexWrap",
  "flexGrow",
  "flexShrink",
  "flex",
  "alignSelf",
  "border",
  "borderTop",
  "borderRight",
  "borderBottom",
  "borderLeft",
  "borderColor",
  "borderRadius",
  "borderBottomLeftRadius",
  "borderBottomRightRadius",
  "borderTopLeftRadius",
  "borderTopRightRadius",
  "opacity",
  "transition",
  "transform",
  "transformOrigin",
  "fontFamily",
  "fontSize",
  "fontWeight",
  "color",
  "textAlign",
  "lineHeight",
  "whiteSpace",
  "textOverflow",
  "textDecoration",
  "textShadow",
  "iconSize",
  "iconColor",
  "objectFit",
  "animation",
  "wordBreak",
  "content",
  "cursor",
  "grid",
  "columnGap",
  "gap",
  "gridArea",
  "gridAutoColumns",
  "gridAutoFlow",
  "gridAutoRows",
  "gridColumn",
  "gridColumnEnd",
  "gridColumnGap",
  "gridColumnStart",
  "gridGap",
  "gridRow",
  "gridRowEnd",
  "gridRowGap",
  "gridRowStart",
  "gridTemplate",
  "gridTemplateAreas",
  "gridTemplateColumns",
  "gridTemplateRows",
  "rowGap",
  "resize",
  "outline",
  "backdropFilter",
  "letterSpacing",
  "fill",
  "verticalAlign",
  "borderStyle",
  "borderWidth",
  "textTransform",
];

const webkitProperties = [
  "appearance",
  "lineClamp",
  "boxOrient",
  "backgroundClip",
  "userDrag",
  "userSelect",
];

const pseudoSelectors = [
  "_hover",
  "_active",
  "_focus",
  "_focusVisible",
  "_focusWithin",
  "_visited",
  "_before",
  "_after",
  "_default",
  "_placeholder",
  "_placeholdershown",
  "_disabled",
];

const childSelectors = [">", "&"];

const setFromTheme = {
  backgroundColor: "background",
  background: "background",
  fill: "background",
  color: "text",
  borderColor: "border",
  font: "font",
  padding: "padding",
  paddingTop: "padding",
  paddingRight: "padding",
  paddingBottom: "padding",
  paddingLeft: "padding",
  margin: "margin",
  marginTop: "margin",
  marginRight: "margin",
  marginBottom: "margin",
  marginLeft: "margin",
  fontsize: "fontSize",
};

const getPropertyType = (property, value) => {
  const conditions = [
    childSelectors.some((selector) => property?.includes(selector)) && "child",
    pseudoSelectors.includes(property) && typeof value === "object" && "pseudo",
    properties.includes(property) && "style",
    webkitProperties.includes(property) && "webkit",
  ];

  return conditions.find((type) => type);
};

const getIsSupportedValue = (value) => {
  const supportedTypes = {
    function: true,
    object: true,
    string: true,
    number: true,
  };
  return supportedTypes?.[typeof value];
};

const getThemeValue = (key, value, theme) => {
  if (typeof value === 'function') {
    return value(theme);
  }

  const themeKey = setFromTheme?.[key];
  return themeKey && theme?.[themeKey]?.[value];
};

const hyphenate = (string) =>
  string?.replace(/[A-Z]/g, (m) => `-${m?.toLowerCase()}`);

const styleFormatter = {
  child: (key, value) => `${key} {${value}}`,
  pseudo: (key, value) => `&${key.replace("_", ":")} {${value}}`,
  style: (key, value) => `${hyphenate(key)}: ${value}`,
  webkit: (key, value) => `-webkit-${hyphenate(key)}; ${value}`,
};

const stylesFromObject = (obj, theme) => {
  const styleArray = Object.keys(obj).reduce((acc, key) => {
    let value = obj[key];
    const propertyType = getPropertyType(key, value);
    const isSupportedValue = getIsSupportedValue(value);

    if (!propertyType || !isSupportedValue) {
      return acc;
    }

    if (["child", "pseudo"].includes(propertyType)) {
      value = stylesFromObject(value, theme);
    } else {
      value = getThemeValue(key, value, theme) || value;
    }

    return [...acc, styleFormatter?.[propertyType](key, value)];
  }, []);
  return `${styleArray.join(";")}`;
};

const propStyles = (props) => stylesFromObject(props, props.theme);

export { propStyles, properties, webkitProperties, pseudoSelectors };

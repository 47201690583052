import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from ".";

const PrivateRoute = ({children}) => {
  const { isLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  if ((location.pathname === "/auth" || location.pathname === "/signup") && isLoggedIn) { 
    return <Navigate to="/" replace />;
  }
  if (isLoggedIn) {
    return children;
  } else {
    return <Navigate to="/auth" replace state={{ from: location }} />;
  }
};

export default PrivateRoute;

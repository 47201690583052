import { Outlet, useRouteError } from "react-router-dom";
import { Container } from "../components";
import Header from "./Header";

export default function Dashoard() {
  const error = useRouteError();
  console.error(error);
  return (
    <Container textAlign="center">
        <Header />
       <Outlet />
    </Container>
  );
}

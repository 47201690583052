import React, { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { apiUrl } from "../default";
import Cookies from "js-cookie";

const AuthForm = ({ type }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(AuthContext);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/auth/${type}`, {
        username,
        email,
        password,
      });

      if (response.status === 200 && type === "login") {
        // document.cookie = `authToken=${response.data.token};`;
        Cookies.set("authToken", response.data.token);
        setIsLoggedIn(true);
        navigate("/");
      }
      setUsername("");
      setEmail("");
      setPassword("");
      setEmail("");
    } catch (err) {
      console.error(err);
      if (err.response) {
        console.log("Error Response:", err.response);
      }
      if (err.response && err.response.data.message) {
        setMessage(err.response.data.message);
      } else {
        setMessage("An error occurred");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>{type === "signup" ? "Sign Up" : "Log In"}</h2>
      {type === "signup" && (
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
      )}
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit">{type === "signup" ? "Sign Up" : "Log In"}</button>
      {message && <p className="error-message">{message}</p>}{" "}
      {/* Basic error display */}
    </form>
  );
};

export default AuthForm;

import React, { useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import { apiUrl } from '../default';

const Header = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = async () => {
    try {
      // Make a server-side logout API call
      await axios.post(`${apiUrl}/auth/logout`);

      // Clear the client-side authToken cookie
      document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

      // Update the authentication state
      setIsLoggedIn(false);

      // Redirect the user to the login page
      navigate('/auth');
    } catch (error) {
      console.error('Error during logout:', error);
      // Handle logout error
    }
  };

  useEffect(() => {
    // Check if the user is logged in on component mount
    const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)authToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (!authToken) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  return (
    <header className="header">
      <nav>
        <ul>
          <li>
            <Link to="/react-page">Home</Link>
          </li>
          <li>
            <Link to="/todo">Todo</Link>
          </li>
          <li>
            <Link to="/userform">Contact</Link>
          </li>
          {/* Add more navigation links as needed */}
        </ul>
      </nav>
      {isLoggedIn && (
        <button className="logout-btn" onClick={logout}>
          Logout
        </button>
      )}
    </header>
  );
};

export default Header;
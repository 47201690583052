import { useRouteError } from "react-router-dom";
import { Container, Text } from "../components";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container textAlign="center">
        <Container
          backgroundColor="#282c34"
          minHeight="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          fontSize="calc(10px + 2vmin)"
          color="white"
        >
      <Text>Oops!</Text>
      <Text>Sorry, an unexpected error has occurred.</Text>
      <Text>
       {error.statusText || error.message}
      </Text>
      </Container>
    </Container>
  );
}

import React, { useState } from "react";
import axios from "axios"; // Assuming you have axios installed
import { apiUrl } from "../default";

function UserForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ name: "", email: "" });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
    try {
      const response = await axios.post(`${apiUrl}/form`, {
        name: name,
        email: email,
      });

      console.log("Success:", response); // Handle success (e.g., display a message)
    } catch (error) {
      console.error("Error submitting data:", error);
      // Handle error (e.g., display an error message)
    }
    setName('');
      setEmail('');
      setErrors({});
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email address';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="user-form-container">
      <h2>User Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={validateForm}
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validateForm}
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>
        <button type="submit" className="submit-btn">
          Submit
        </button>
      </form>
    </div>
  );
}

export default UserForm;

import { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { apiUrl } from "../../default";

const AuthContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const validateAuth = async () => {
    console.count("counting");
    const token = Cookies.get("authToken");

    if (token) {
      try {
        const response = await axios.get(`${apiUrl}/auth/validate`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.valid) {
          setIsLoggedIn(true);
        } else {
          // Token is invalid, try to refresh the token
          const refreshResponse = await axios.post(
            `${apiUrl}/auth/refresh`,
            null,
            {
              withCredentials: true,
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (refreshResponse.data.newToken) {
            Cookies.set("authToken", refreshResponse.data.newToken, {
              sameSite: "strict",
              secure: true,
            });
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
            Cookies.remove("authToken");
          }
        }
      } catch (err) {
        setIsLoggedIn(false);
        Cookies.remove("authToken");
      }
    } else {
      setIsLoggedIn(false);
    }

    setIsChecking(false);
  };

  useEffect(() => {
    validateAuth();
  }, [isLoggedIn]);

  useEffect(() => {
    const tokenValidationInterval = setInterval(() => {
      if (isLoggedIn) {
        validateAuth();
      }
    }, 5 * 60 * 1000);

    return () => clearInterval(tokenValidationInterval);
  }, [isLoggedIn]);

  const logout = () => {
    setIsLoggedIn(false);
    Cookies.remove("authToken");
  };

  if (isChecking) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
import React, { useState } from 'react';
import AuthForm from './AuthForm';

const AuthToggle = () => {
  const [showLogin, setShowLogin] = useState(true);

  const toggleForm = (value) => {
    setShowLogin(value);
  };

  return (
    <div className="auth-toggle-container">
      <div className="toggle-buttons">
        <button
          className={`toggle-btn ${showLogin ? 'active' : ''}`}
          onClick={() => toggleForm(true)}
        >
          Log In
        </button>
        <button
          className={`toggle-btn ${!showLogin ? 'active' : ''}`}
          onClick={() => toggleForm(false)}
        >
          Sign Up
        </button>
      </div>
      <div className="auth-form-container">
        <AuthForm type={showLogin ? 'login' : 'signup'} />
      </div>
    </div>
  );
};

export default AuthToggle;

import React, { forwardRef } from "react";

const styleFilter = (Component) =>
  forwardRef(
    (
      {
        display,
        show,
        visibility,
        visible,
        width,
        minWidth,
        maxWidth,
        height,
        minHeight,
        maxHeight,
        position,
        top,
        right,
        bottom,
        left,
        zIndex,
        overflow,
        overflowX,
        overflowY,
        padding,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        pointerEvents,
        margin,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        background,
        backgroundColor,
        backgroundImage,
        backgroundPosition,
        backgroundRepeat,
        backgroundSize,
        backgroundClip,
        boxShadow,
        flexDirection,
        alignItems,
        alignContent,
        justifyContent,
        flexWrap,
        flexGrow,
        flexShrink,
        flex,
        alignSelf,
        border,
        borderTop,
        borderRight,
        borderBottom,
        borderLeft,
        borderColor,
        borderRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
        borderTopLeftRadius,
        borderTopRightRadius,
        opacity,
        transition,
        transform,
        transformOrigin,
        fontFamily,
        fontSize,
        fontWeight,
        color,
        textAlign,
        lineHeight,
        whiteSpace,
        textOverflow,
        textDecoration,
        textShadow,
        iconSize,
        iconColor,
        objectFit,
        animation,
        wordBreak,
        content,
        cursor,
        grid,
        columnGap,
        gap,
        gridArea,
        gridAutoColumns,
        gridAutoFlow,
        gridAutoRows,
        gridColumn,
        gridColumnEnd,
        gridColumnGap,
        gridColumnStart,
        gridGap,
        gridRow,
        gridRowEnd,
        gridRowGap,
        gridRowStart,
        gridTemplate,
        gridTemplateAreas,
        gridTemplateColumns,
        gridTemplateRows,
        rowGap,
        outline,
        backdropFilter,
        letterSpacing,
        appearance,
        lineClamp,
        boxOrient,
        fill,
        _hover,
        _active,
        _focus,
        _focusVisible,
        _focuswithin,
        _visited,
        _before,
        _after,
        _default,
        _placeholder,
        _placeholdershown,
        _disabled,
        ...props
      },
      ref
    ) => <Component ref={ref} {...props} />
  );

  export default styleFilter;

import React from "react";
import { Anchor, Container, Image, Text } from "../components";
import logo from "../logo.svg";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import slides from "./slides";

const ReactPage = () =>{
  const [advancedExampleOpen, setAdvancedExampleOpen] = React.useState(false);
    return (
        <Container textAlign="center">
          <Lightbox
        open={advancedExampleOpen}
        close={() => setAdvancedExampleOpen(false)}
        slides={slides}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
        <Container
          backgroundColor="#282c34"
          minHeight="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          fontSize="calc(10px + 2vmin)"
          color="white"
        >
          <Image
            src={logo}
            className="App-logo"
            alt="logo"
            height="40vmin"
            pointerEvents="none"
            
          />
          <Text
            _hover={{
              backgroundColor: "yellow",
              color: "red",
            }}
          >
            Edit src/App.js and save to reload.
          </Text>
          <Anchor
            className="App-link"
            onClick={() => setAdvancedExampleOpen(true)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </Anchor>
        </Container>
      </Container>
    )
}

export default ReactPage;
import styled from "styled-components";
import { propStyles } from "../styleHelper";
import styleFilter from "../styleFilter";

const Anchor = styled(styleFilter(styled.a``))`
    text-decoration: none;
    cursor: pointer;
    color: '#323244';
  ${propStyles}
`;

export default Anchor;

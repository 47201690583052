import styled from "styled-components";
import { propStyles } from "../styleHelper";
import styleFilter from "../styleFilter";

const Text = styled(styleFilter(styled.span``))`
line-height: 1.4em;
  ${propStyles}
`;

export default Text;

import { createBrowserRouter, Navigate } from "react-router-dom";
import AuthToggle from "../pages/AuthToggle";
import ErrorPage from "../pages/ErrorPage";
import PrivateRoute from "../context/AuthContext/PrivateRoute";
import UserForm from "../pages/UserForm";
import Todo from "../pages/Todo";
import ReactPage from "../pages/ReactPage";
import Dashboard from "../pages/Dashboard";

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <AuthToggle />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "userform",
        element: <UserForm />,
      },
      {
        path: "todo",
        element: <Todo />,
      },
      {
        path: "react-page",
        element: <ReactPage />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

export default router;
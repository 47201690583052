import "./App.css";
import { createGlobalStyle } from "styled-components";
import {
  RouterProvider,
} from "react-router-dom";
import { AuthProvider } from './context/AuthContext';
import router from "./routes";
import "yet-another-react-lightbox/styles.css";
const GlobalStyle = createGlobalStyle`
body,html{
  margin:0;
  font-size: 16px;
  height: 1px;
  min-height: 100%;
  box-sizing: border-box;
}
*,*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
button {
  border: none;
  background: none;
  &:focus {
    outline : none;
  }
}
`;



function App() {
  
  return (
    <AuthProvider>
      <GlobalStyle />      
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;

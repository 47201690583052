// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../default';

function Todo() {
  const [todos, setTodos] = useState([]);
  const [newTodoText, setNewTodoText] = useState('');

  useEffect(() => {
    fetchTodos();
  }, []);

  const fetchTodos = async () => {
    const response = await axios.get(`${apiUrl}/todos`);
    setTodos(response.data);
  };

  const addTodo = async () => {
    if (newTodoText.trim() === '') return;
    const response = await axios.post(`${apiUrl}/todos`, { task: newTodoText });
    setTodos([...todos, response.data]);
    setNewTodoText(''); // Clear the input after adding
  };

  const toggleComplete = async (todo) => {
    const response = await axios.patch(`${apiUrl}/todos/${todo._id}`, {
      completed: !todo.completed,
    });
    setTodos(todos.map((t) => (t._id === todo._id ? response.data : t)));
  };

  const deleteTodo = async (id) => {
    await axios.delete(`ec2-13-201-194-154.ap-south-1.compute.amazonaws.com/todos/${id}`);
    setTodos(todos.filter((t) => t._id !== id)); // Filter out the deleted todo
  };

  return (
    <div className="todo-container">
      <h1>My ToDo List</h1>
      <div className="input-area">
        {/* Styling in your App.css */}
        <input
          type="text"
          value={newTodoText}
          onChange={(e) => setNewTodoText(e.target.value)}
          placeholder="Enter a new task"
        />
        <button onClick={addTodo}>Add Task</button>
      </div>
      <ul className="todo-list">
        {todos &&
          todos.map((todo) => (
            <li key={todo._id} className={todo.completed ? 'completed' : ''}>
              <span onClick={() => toggleComplete(todo)}>{todo.task}</span>
              <div>
                <button onClick={() => toggleComplete(todo)}>
                  {todo.completed ? 'Incomplete' : 'Complete'}
                </button>
                <button onClick={() => deleteTodo(todo._id)}>Delete</button>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Todo;